'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import type { Dictionary } from 'types';

import { BRAND_CODE } from '@/config/common';
import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { cn } from '@/lib/utils';

import { Heading } from '../ui';
import VoyadoCountrySelect from './VoyadoCountrySelect';
import VoyadoFormInputField from './VoyadoFormInputField';
import VoyadoFormSubmitButton from './VoyadoFormSubmitButton';
import { useCreateVoyadoContact } from './VoyadoSignUpForm.hooks';
import {
    type VoyadoContact,
    voyadoContactSchema,
} from './VoyadoSignUpForm.schema';
import VoyadoSignupFormSuccess from './VoyadoSignupFormSuccess';

export default function VoyadoSignUpForm({
    className,
    locale,
    reference,
    dictionary,
}: {
    className?: string;
    locale?: string;
    reference?: string | null;
    dictionary: Dictionary;
}) {
    const marketConfig = getMarketConfigFromLocale(locale);
    const methods = useForm<VoyadoContact>({
        resolver: zodResolver(voyadoContactSchema),
    });
    const {
        mutate: createVoyadoContactMutation,
        isSuccess,
        isPending,
        isError,
        error,
    } = useCreateVoyadoContact({
        label: reference ?? 'voyado-sign-up-form',
    });

    const onSubmit = methods.handleSubmit((data) => {
        createVoyadoContactMutation({
            brandCode: BRAND_CODE,
            contact: {
                ...data,
                countryCode: data.countryCode ?? marketConfig.code,
                externalStoreId: marketConfig.externalStoreId,
                source: reference ?? 'voyado-sign-up-form',
            },
        });
    });

    // If the user is already signed up we get an error with "Conflict" back from the CRM service.
    // We handle this error by a success message to the user, which is better than nothing happens / showing an error message.
    if (isSuccess || (isError && error.message.includes('Conflict'))) {
        return <VoyadoSignupFormSuccess dictionary={dictionary} />;
    }

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={onSubmit}
                className={cn(
                    'flex flex-col gap-2 bg-white p-4 text-black',
                    className,
                )}>
                {dictionary?.voyadoSignUpFormHeading && (
                    <Heading
                        className="lg:mb-3"
                        size={{
                            initial: 5,
                            lg: 4,
                        }}>
                        {dictionary?.voyadoSignUpFormHeading}
                    </Heading>
                )}
                <div className="grid grid-cols-2 gap-2">
                    <VoyadoFormInputField
                        type="text"
                        name="firstName"
                        label={
                            dictionary?.voyadoSignUpFormFirstNameLabel ??
                            'First name'
                        }
                        required
                    />
                    <VoyadoFormInputField
                        type="text"
                        name="lastName"
                        label={
                            dictionary?.voyadoSignUpFormLastNameLabel ??
                            'Last name'
                        }
                        required
                    />
                </div>
                <VoyadoFormInputField
                    type="email"
                    name="email"
                    label={
                        dictionary?.voyadoSignUpFormEmailLabel ??
                        'Email address'
                    }
                    required
                />
                <VoyadoFormInputField
                    type="tel"
                    name="mobilePhone"
                    label={
                        dictionary.voyadoSignUpFormPhoneNumberLabel ??
                        'Phone number'
                    }
                    required
                />
                <VoyadoFormInputField
                    type="text"
                    name="street"
                    label={
                        dictionary.voyadoSignUpFormStreetLabel ??
                        'Your street address'
                    }
                />
                <div className="grid grid-cols-2 gap-2">
                    <VoyadoFormInputField
                        type="text"
                        name="zipCode"
                        label={
                            dictionary.voyadoSignUpFormZipCodeLabel ??
                            'Zip code'
                        }
                    />
                    <VoyadoFormInputField
                        type="text"
                        name="city"
                        label={dictionary.voyadoSignUpFormCityLabel ?? 'City'}
                    />
                </div>
                <VoyadoFormInputField
                    type="text"
                    name="company"
                    label={
                        dictionary.voyadoSignUpFormCompanyLabel ??
                        'Company name'
                    }
                    required
                />
                <VoyadoFormInputField
                    type="text"
                    name="companyTitle"
                    label={
                        dictionary.voyadoSignUpFormCompanyTitleLabel ??
                        'Your role / title'
                    }
                    required
                />
                <VoyadoCountrySelect
                    marketConfig={marketConfig}
                    label={dictionary?.country}
                    name="countryCode"
                />
                <VoyadoFormSubmitButton isLoading={isPending}>
                    {dictionary?.voyadoSignUpFormSubmitButton ?? 'Sign up'}
                </VoyadoFormSubmitButton>
                {isError && !error?.message.includes('Conflict') && (
                    <p className="text-sm text-red-500">
                        {dictionary.voyadoSignUpFormErrorMessage ??
                            'An error occurred. Please try again later.'}
                    </p>
                )}
            </form>
        </FormProvider>
    );
}
